<template>
  <div class="sc-wrapper">
    <Configurator ref="configurator" :orgId="orgId"/>
    <custom-full-calendar
        v-if="renderCalendar"
        v-model:date="date"
        :events="events"
        :holidays="getHolidays"
        :type="calendarType"
        :view="calendarView"
        :viewTypes="calendarViewTypes"
    />
  </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from 'vuex';
import moment from 'moment';
import {WeekDayEnum} from '@/enums/week-day.enum.js';
import CustomFullCalendar from '@/components/CustomFullCalendar';
import {ViewEnum} from '@/components/CustomFullCalendar/enums/view.enum';
import {ViewTypeEnum} from '@/components/CustomFullCalendar/enums/view-type.enum';
import Configurator from './configurator';
// import _ from 'lodash';

export default {
  name: 'calendar-configurator',
  components: {
    'custom-full-calendar': CustomFullCalendar,
    Configurator,
  },
  props: {
    orgId: {
      type: [String, Number],
      default: "1",
      required: true,
    },
  },
  data: () => ({
    checked: false,
    participants: [],
    selectedAlarmPlan: null,
    date: moment(),
    events: [],
    firstRun: true,
    calendarView: ViewEnum.TIMELINE,
    calendarType: ViewTypeEnum.MONTHLY,
    calendarViewTypes: Object.values(ViewTypeEnum).filter(i => i !== ViewTypeEnum.YEAR).map(i => ({
      name: i.toLowerCase(),
      code: i
    })),
    invalidEvents: [],
    renderCalendar: true,
  }),
  created() {
    this.moment = moment;
  },
  async mounted() {
    await this.callGetHolidays({orgId: this.orgId, year: moment(this.date).year()});
    await this.callParticipants(this.orgId);
    await this.callEvents();
  },
  methods: {
    ...mapActions({
      'callEvents': 'participantCalendar/callEvents',
      'callParticipants': 'participantCalendar/callParticipants',
      'callCreateCalendar': 'participantCalendar/callCreateCalendar',
      'callUpdateCalendar': 'participantCalendar/callUpdateCalendar',
      'callRemoveCalendar': 'participantCalendar/callRemoveCalendar',
      'callGetHolidays': 'holidays/callGetHolidays',
    }),
    ...mapMutations({
      'setCalendar': 'participantCalendar/setCalendar',
      'setIsEditable': 'participantCalendar/setIsEditable',
      'createWeek': 'participantCalendar/createWeek',
      'removeWeek': 'participantCalendar/removeWeek',
      'createEvent': 'participantCalendar/createEvent',
      'removeEvent': 'participantCalendar/removeEvent',
      'cloneEvent': 'participantCalendar/cloneEvent',
    }),
    removeSelectedEvent(weekGroupIndex, eventIndex) {
      this.$confirm.require({
        message: this._t('label_confirm_deletion'),
        header: this._t('label_Confirmation'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this._t('label_yes'),
        rejectLabel: this._t('label_no'),
        accept: () => {
          this.removeEvent({weekGroupIndex, eventIndex});
          this.validateWeeks();
        },
        reject: () => {
          // nothing to do
        }
      });
    },
    createUpdateNewCalendar() {
      this.$refs.configurator.createUpdateNewCalendar();
    },
    generateEvents: function () {
      if (!this.getEvents) return [];
      let events = [];
      const eventGroups = JSON.parse(JSON.stringify(this.getEvents));
      const countWeeks = eventGroups.length;
      let currentWeekInMonth = Math.ceil(
          moment(this.dateStartWeekMonth).diff(this.validFrom, 'weeks') % countWeeks
      );
      eventGroups.map((week, weekIndex) => {
        week.participantRotationEvents.map(eventRule => {
          let times = [];
          const calendar = this.getCalendars.find(i => i.id === eventRule.rotationalCalendarId) ?? this.getCalendar;
          const device = eventRule.device !== null ? this.getDevices.find(i => i.message === eventRule.device.message) ?? null : null;
          for (
              let d = moment(this.dateStartWeekMonth);
              d.diff(moment(this.dateEndWeekMonth), 'days') <= 0;
              d.add(1, 'days')
          ) {
            const holiday = this.getHolidays ? this.getHolidays.find(i =>
                moment(i.start).isSameOrBefore(moment(d).endOf('days'))
                && moment(i.end).isSameOrAfter(moment(d).startOf('days'))
            ) : null;
            if (currentWeekInMonth === weekIndex && d.isSameOrAfter(calendar?.validFrom)) {
              // check day of week
              if (/*d.week() === moment(d).startOf('months').add(weekIndex, 'weeks').week()
                  && d.isSameOrAfter(calendar?.validFrom)
                  &&*/ eventRule.dateRule.markedDays.indexOf(d.format('dddd').toLocaleUpperCase()) !== -1
              ) {
                if (!holiday) {
                  times.push({
                    start: d.set({
                      hour: eventRule.dateRule.start.split(':')[0],
                      minutes: eventRule.dateRule.start.split(':')[1]
                    }).format('YYYY-MM-DD HH:mm:ss'),
                    end: d.set({
                      hour: eventRule.dateRule.end.split(':')[0],
                      minutes: eventRule.dateRule.end.split(':')[1]
                    }).format('YYYY-MM-DD HH:mm:ss'),
                    isHoliday: eventRule.dateRule.holiday,
                  });
                }
              }
              // check holidays
              if (eventRule.dateRule.holiday && holiday) {
                times.push({
                  start: d.set({
                    hour: eventRule.dateRule.start.split(':')[0],
                    minutes: eventRule.dateRule.start.split(':')[1]
                  }).format('YYYY-MM-DD HH:mm:ss'),
                  end: d.set({
                    hour: eventRule.dateRule.end.split(':')[0],
                    minutes: eventRule.dateRule.end.split(':')[1]
                  }).format('YYYY-MM-DD HH:mm:ss'),
                  isHoliday: eventRule.dateRule.holiday,
                  holiday,
                });
              }
            }

            if (moment(d).diff(moment(this.validFrom).subtract(1, 'days'), 'weeks') < 0) {
              currentWeekInMonth = 0;
            } else {
              currentWeekInMonth = Math.ceil(moment(d).diff(moment(this.validFrom).subtract(1, 'days'), 'weeks') % countWeeks);
            }
            if (times.length) {
              let event = eventRule.device ? events.find(i => i.device.message === eventRule.device.message) ?? null : null;
              if (event) {
                event.times = event.times.concat(times);
              } else {
                event = {
                  title: (device?.message || 'None'),
                  device: eventRule.device,
                  times,
                };
                events.push(event);
              }
              times = [];
            }
          }
        })
      });

      this.events = events;
      if (this.firstRun) {
        // This is a fix, do not delete this block
        this.firstRun = false;
        this.date = moment();
      }
      this.validateWeeks();
      return events;
    },
    validateWeeks: function () {
      this.$refs.configurator.validateWeeks();
    },
    forceRerender() {
      this.renderCalendar = false;
      this.$nextTick(() => {
        this.renderCalendar = true;
      });
    },
  },
  computed: {
    ...mapGetters({
      'getCalendars': 'participantCalendar/getCalendars',
      'getCalendar': 'participantCalendar/getCalendar',
      'getEvents': 'participantCalendar/getEvents',
      'getParticipants': 'participantCalendar/getParticipants',
      'getIsEditable': 'participantCalendar/getIsEditable',
      'getHolidays': 'holidays/getHolidays',
      'getDevices': 'devices/getDevices',
    }),
    dateStartMonth() {
      return this.moment(this.date).startOf('months').format('YYYY-MM-DD');
    },
    dateEndMonth() {
      return this.moment(this.date).endOf('months').format('YYYY-MM-DD');
    },
    dateStartWeekMonth() {
      return this.moment(this.dateStartMonth).startOf('isoWeeks').format('YYYY-MM-DD');
    },
    dateEndWeekMonth() {
      return this.moment(this.dateEndMonth).endOf('isoWeeks').format('YYYY-MM-DD');
    },
    validFrom: {
      get() {
        let calendar = this.getCalendar;
        if (calendar) {
          return this.moment(calendar.validFrom, 'YYYY-MM-DD HH:mm:ss').toDate();
        }
        return null;
      },
      set(val) {
        let calendar = this.getCalendar;
        calendar.validFrom = this.moment(val).format('YYYY-MM-DD HH:mm:ss');
        this.setCalendar(calendar);
      }
    },
    weekDay: () => WeekDayEnum,
  },
  watch: {
    getEvents: function () {
      this.generateEvents();
    },
    date: function () {
      if (this.getCalendar) {
        this.generateEvents();
        this.callGetHolidays({orgId: this.orgId, year: moment(this.date).year()});
      }
    },
  },
}
</script>

<style scoped>
:deep(.sc-calendar__header) {
  background-color: #d6ddd87d;
}

:deep(.invalid) {
  background-color: #D32F2F42;
}

:deep(.p-inputmask.p-inputtext.p-component.p-filled) {
  width: 80%;
}
</style>
